function EducationGrid(){

    const eduData = {
        "Location": "New York, NY",
        "Attended From" : "09/2017 – 05/2021",
        "Major": "Computer Science",
        "Degree" : "Bachelor of Arts",
        "GPA": "3.3",
        "Stern Economic Honor Society" : "Technology Chair",
        "Alpha Epsilon Pi Fraternity": "Member"
    }
    
    return (
        <ul className='grid-container'>
            {Object.keys(eduData).map((item,index) => {
                return(           
                <li key={index} className="grid-item">
                    <span>{item}</span>
                    <hr></hr>
                    {eduData[item]}
                </li>
                )
            })}
        </ul>
    )
}

export default EducationGrid;
