import '../styles/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookF, faGithub, faInstagram, faInstagramSquare, faLinkedinIn, faTwitter} from '@fortawesome/free-brands-svg-icons'

function Footer(){
    return(
        <div id="footer">
            <ul>
                <li><a className="socials" href="https://www.instagram.com/ryanschwarz99/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li><a className="socials" href="https://twitter.com/ryangers99" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a className="socials" href="https://www.facebook.com/ryan.schwarz.714" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                <li><a className="socials" href="https://www.linkedin.com/in/ryanschwarz99/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                <li><a className="socials" href="https://github.com/ryan-schwarz" target="_blank"><FontAwesomeIcon icon={faGithub} /></a></li>
            </ul>
        </div>








    )
}

export default Footer;