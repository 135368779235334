export const data = [
  {
    company: 'Infor',
    location: 'New York, NY',
    date: '09/2021 – Present',
    position: 'Software Engineer, Associate',
    description:
    'Develop reusable, dynamic components for customer using jQuery and HTML. ' +
    'Build out features, as well as resolve bugs, for a new software product using' +
    ' the React & Angular frameworks in TypeScript. Create metadata collector with GoLang that write to AWS DynamoDB.',
  },
  {
    company: 'MSG Networks',
    location: 'New York, NY',
    date: '10/2020 – 05/2021',
    position: 'Website/Digital Student Associate',
    description:
      'Backlogged metadata from Twitter accounts. QA the website every day. Updated content on the corporate' +
      ' website. Researched other websites for potential features to implement on our own site. Ran an email' +
      ' marketing campaign. Prepared in-game modules for the mobile app. Created visual assets via Photoshop for' +
      ' use on social media. ',
  },
  {
    company: 'NYU',
    location: 'New York, NY',
    date: '09/2020 – 12/2020',
    position: 'Tutor – Web Development (JavaScript)',
    description:
      'Assisted the students with their assignments. Attended lectures and answered questions students may have.',
  },
  {
    company: 'Daebakd',
    location: 'New York, NY',
    date: '05/2020 – 09/2020',
    position: 'Front End Web Development Intern',
    description:
      'Worked extensively with designer and back-end web developer to build out the front-end designs in HTML,' +
      ' CSS, and JavaScript given for a new social media website.',
  },
  {
    company: 'Baked by Melissa',
    location: 'New York, NY',
    date: '06/2019 – 08/2019',
    position: 'IT & Digital Intern',
    description:
      'Assembled content pages for the new website using HTML and CSS programming languages to ensure maximum' +
      ' quality displaying the information, along with the responsiveness of the pages for mobile devices. This' +
      ' was done to better the direct to consumer and business to business aspects of the website.',
  },
  {
    company: 'Law Office of Richard Hochhauser, PLLC',
    location: 'New York, NY',
    date: '07/2018 – 08/2018',
    position: 'IT & Web Assistant',
    description:
      'Contributed to Data Architecture Paradigms. Strategized move from legacy system to a cloud-based' +
      ' infrastructure.',
  },
  {
    company: 'Swag.com',
    location: 'New York, NY',
    date: '02/2018 – 03/2018',
    position: 'Product Management Intern',
    description: 'Contributed to Pricing Algorithm. Cataloged and implemented a backlog for product database.',
  },
];
