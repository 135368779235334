import '../styles/portfolio.css';
import { Helmet } from 'react-helmet';
import PortfolioGrid from './portfolio/portfolio-grid';

function Portfolio() {
  return (
    <div id='portfolio' className="animate__animated animate__fadeInDown animate__slow">
      <Helmet>
        <title>Portfolio - Ryan Schwarz</title>
        <meta name='Portfolio' content='Portfolio' />
      </Helmet>

      <h2>PORTFOLIO</h2>
      <PortfolioGrid />
    </div>
  );
}

export default Portfolio;
