import React, { ReactElement, useState } from "react"
import TabTitle from "./tabtitle.js"
import '../styles/tabs.css'



function Tabs (props){
  const [selectedTab, setSelectedTab] = useState(0)
  const [active, setActive] = useState('')

  function findactive(index){
      if (index == selectedTab){
        return "active";
      }
      else{
          return "inactive"
      }
  }

  return (
    <div id="tabs">
      <ul id="tabsList">

        {props.children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            class={findactive(index)}
          />
            ))}
      </ul>
      {props.children[selectedTab]}
    </div>
  )
}

export default Tabs