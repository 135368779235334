import Typist from 'react-typist';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/home.css';
import Photogrid from './photogrid.js';
import Contact from './contact.js';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home - Ryan Schwarz</title>
        <meta name='Home' content='Home' />
      </Helmet>
      <div className='home'>
        <div className='home-content animate__animated animate__slideInLeft animate__slow'>
          <Typist className='Typewriter' cursor={{ hideWhenDone: true }}>
            <span className='wave'>👋</span>
            <span className='wave'>👋</span>
            <span className='wave'>👋</span>, I'M RYAN!
          </Typist>

          <p>I have a passion for building out (and sometimes designing my own) websites and projects.</p>
         
          <p>
            I built this website using the React and Express frameworks initially with little familiarity in the
            discipline. With my formal computer science education and my self-taught abilities, I exhibit a solid work
            ethic and discipline of iron. My passion to deliver compelling interactive experience is tempered only by
            the need to meet deadlines and keep my clients smiling.
          </p>
        </div>
        <div className='photogrid animate__animated animate__slideInLeft animate__slow'>
          {/* <Photogrid /> */}
          <img src='ryanCollage3.png'></img>
        </div>
      </div>
      <div className='contact'></div>
    </div>
  );
}

export default Home;
