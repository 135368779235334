import '../styles/contactMobile.css';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

const ContactMobile = () => {
  const isMobile = window.innerWidth <= 750;

  const [status, setStatus] = useState('Send Message');
  const [errors, setErrors] = useState({ name: 'E', email: 'E', message: 'E' });

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const myChangeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const myErrors = {};
    switch (name) {
      case 'name':
        myErrors.fname = value.replace(/\s/g, '').length < 1 ? 'Cannot leave name blank!' : '';
        setErrors((prevState) => {
          return { ...prevState, name: myErrors.fname };
        });
        break;
      case 'email':
        if (value.length < 1) {
          myErrors.email = value.replace(/\s/g, '').length < 1 ? 'Cannot leave email blank!' : '';
        } else {
          myErrors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!';
        }

        setErrors((prevState) => {
          return { ...prevState, email: myErrors.email };
        });
        break;
      case 'message':
        myErrors.message = value.replace(/\s/g, '').length < 1 ? 'Cannot send blank message!' : '';

        setErrors((prevState) => {
          return { ...prevState, message: myErrors.message };
        });
        break;
    }
    console.log(errors);
  };

  // const validateForm = (errors) => {
  //   let valid = true;
  //   Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  //   return valid;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const myErrors = {};
    myErrors.fname =
      document.getElementById('name').value.replace(/\s/g, '').length < 1 ? 'Cannot leave name blank!' : '';
    setErrors((prevState) => {
      return { ...prevState, name: myErrors.fname };
    });

    if (document.getElementById('email').value.length <= 1) {
      myErrors.email =
        document.getElementById('email').value.replace(/\s/g, '').length < 1 ? 'Cannot leave email blank!' : '';
    } else {
      myErrors.email = validEmailRegex.test(document.getElementById('email').value) ? '' : 'Email is not valid!';
    }

    setErrors((prevState) => {
      return { ...prevState, email: myErrors.email };
    });

    myErrors.message =
      document.getElementById('message').value.replace(/\s/g, '').length < 1 ? 'Cannot send blank message!' : '';

    setErrors((prevState) => {
      return { ...prevState, message: myErrors.message };
    });

    if (errors.email.length == 0 && errors.name.length == 0 && errors.message.length == 0) {
      console.info('Valid Form');
      setStatus('Sending...');
      const { name, email, message } = e.target.elements;
      let details = {
        name: name.value,
        email: email.value,
        message: message.value,
      };
      emailjs
        .send(process.env.REACT_APP_EMAIL_1, process.env.REACT_APP_EMAIL_2, details, process.env.REACT_APP_EMAIL_3)
        .then(
          (result) => {},
          (error) => {}
        );
      // let response = await fetch('/contact', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json;charset=utf-8',
      //   },
      //   body: JSON.stringify(details),
      // });
      setStatus('Submit');
      // let result = await response.json();
      // document.getElementsByClassName('pu-content-container2')[0].classList.add('sent');

      //hideLightBox();

      // alert(result.status);
    } else {
      console.error('Invalid Form');
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setStatus('Sending...');
  //   const { name2, email2, message2 } = e.target.elements;
  //   let details = {
  //     name: name2.value,
  //     email: email2.value,
  //     message: message2.value,
  //   };
  //   let response = await fetch('/contact', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json;charset=utf-8',
  //     },
  //     body: JSON.stringify(details),
  //   });
  //   setStatus('Submit');
  //   let result = await response.json();
  //   window.location.reload();
  //   // alert(result.status);
  // };

  if (isMobile) {
    return (
      <div className='pu-content-container2'>
        <Helmet>
          <title>Contact - Ryan Schwarz</title>
          <meta name='Contact' content='Contact' />
        </Helmet>
        {status == 'Send Message' && (
          <center>
            <h1>HIT ME UP</h1>
            <p>Want to learn more about Ryan? Have any inquiries about potential projects? Shoot me a message!</p>
          </center>
        )}
        {status == 'Send Message' && (
          <form onSubmit={handleSubmit}>
            <div className='form-item'>
              <label for='name'>Your Name</label>
              <input
                className={errors.name.length > 1 ? 'errorBorder' : 'normalBorder'}
                type='text'
                id='name'
                name='name'
                // onClick={myChangeHandler}
                onBlur={myChangeHandler}
                onChange={myChangeHandler}
              />
              {errors.name.length > 1 ? <span className='error'>{errors.name}</span> : null}
            </div>

            <div className='form-item'>
              <label for='email'>
                <span>Email</span>
                <input
                  type='text'
                  name='email'
                  className={errors.email.length > 1 ? 'errorBorder' : 'normalBorder'}
                  id='email'
                  // onClick={myChangeHandler}
                  onBlur={myChangeHandler}
                  onChange={myChangeHandler}
                />
              </label>
              {errors.email.length > 1 ? <span className='error'>{errors.email}</span> : null}
            </div>

            <div className='form-item'>
              <label for='message'>
                <span>Message</span>
                <textarea
                  className={errors.message.length > 1 ? 'errorBorder' : 'normalBorder'}
                  // onClick={myChangeHandler}
                  onBlur={myChangeHandler}
                  onChange={myChangeHandler}
                  name='message'
                  id='message'
                />
              </label>
              {errors.message.length > 1 ? <span className='error'>{errors.message}</span> : null}
            </div>

            <input type='submit' value={status} id='contactSubmit' />
          </form>
        )}
        {status == 'Submit' && (
          <center>
            <h1>MESSAGE SENT</h1>
            <p>Thanks for reaching out! I'll get back to you soon!</p>
            <p>Want to send another message? <u><span onClick={() => setStatus("Send Message")}>Click Here</span></u></p>
          </center>
        )}
      </div>
    );
  } else {
    window.location.href = '/';
  }
};

export default ContactMobile;
