import Sidebar from './sidebar.js';
import React, { useState, useEffect } from 'react';
import '../styles/navbar.css';
import Contact from './contact.js';
import { Link } from "react-router-dom";

function NavBar() {
  const [popUp, setPopUp] = useState(false)
  const [myClass, setClass] = useState('out')


  const showLightBox = () => {
    setPopUp(true);
    setClass('showing')
    

  };

  
  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 50,
        headerEl = document.getElementById("navbar");
  
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }
    }
    // Update the document title using the browser API
    window.addEventListener("scroll", resizeHeaderOnScroll);
    // return () => window.removeEventListener("scroll", onScroll);
  });
    
  
  
  return (
    <div>
    <header id='navbar'>
      {/* <Sidebar id="wholeSide"/> */}
      <ul id='nav'>
        <li>
          <Link to='/'>
            <img id='logo' src='RyanLogoNew2.png' />
          </Link>
        </li>
        {/* <li>
          <a href='/'>
            <img id='logoMobile' src='RyanLogoMobile2.png' />
          </a>
        </li> */}
        <li>
          <Link to='/about' className="regLink">
            ABOUT
          </Link>
        </li>
        <li>
          <Link className="regLink" to='/resume'>
            RESUME
          </Link>
        </li>
        <li>
          <Link className="regLink" to='/portfolio'>
            PORTFOLIO
          </Link>
        </li>
        <li>
          <a onClick={showLightBox} className="regLink">
            CONTACT
          </a>
        </li>
        
      </ul>
            {popUp && <Contact popUp={popUp} setPopUp={setPopUp}/>}
    </header>


    <header id='navbarMobile'>
      <Sidebar id="wholeSide"/>
      <ul id='nav'>
        <li>
          <Link id="mobileLogo" to='/'>
            <img id='logoMobile' src='NewMobileLogo.png' />
          </Link>
        </li>

        
      </ul>
            {popUp && <Contact setPopUp={setPopUp}/>}
    </header>

    </div>
    
  );
}

export default NavBar;
