import Tab from './tab.js';
import Tabs from './tabs.js';
import '../styles/resume.css';
import { Helmet } from 'react-helmet';
import ScrollAnimation from 'react-animate-on-scroll';
import EducationGrid from './resume/education-grid.js';
import ExperienceMap from './resume/experience-map.js';

function Resume() {
  return (
    <Tabs>
      <Tab title='Education'>
        <Helmet>
          <title>Education - Ryan Schwarz</title>
          <meta name='Education' content='Education' />
        </Helmet>

        <ScrollAnimation animateIn='fadeIn'>
          <div id='education'>
            <div id='eduImage'>
              <img src='nyu3.png'></img>
            </div>
          </div>
          <div>
            <EducationGrid />
          </div>
        </ScrollAnimation>
      </Tab>

      <Tab title='Professional Experience'>
        <Helmet>
          <title>Experience - Ryan Schwarz</title>
          <meta name='Experience' content='Experience' />
        </Helmet>

        <div id='experience'>
          <ExperienceMap />
        </div>
      </Tab>

      <Tab title='Skillset'>
        <Helmet>
          <title>Skillset - Ryan Schwarz</title>
          <meta name='Skillset' content='Skillset' />
        </Helmet>

        <div id='skillset'>
          <div className='bar-graph bar-graph-horizontal bar-graph-one'>
            <p id='coursesHeader'>Skills and Experience (in years)</p>

            <div className='holder bar-zero'>
              <span className='year'>Angular</span>
              <div className='bar' data-percentage='1'></div>
            </div>

            <div className='holder bar-three'>
              <span className='year'>Ajax</span>
              <div className='bar' data-percentage='4'></div>
            </div>

            <div className='holder bar-two'>
              <span className='year'>C</span>
              <div className='bar' data-percentage='3'></div>
            </div>

            <div className='holder bar-four'>
              <span className='year'>CSS</span>
              <div className='bar' data-percentage='5'></div>
            </div>

            <div className='holder bar-two'>
              <span className='year'>Express.js</span>
              <div className='bar' data-percentage='3'></div>
            </div>
            <div className='holder bar-four'>
              <span className='year'>HTML</span>
              <div className='bar' data-percentage='5'></div>
            </div>
            <div className='holder bar-three'>
              <span className='year'>JavaScript</span>
              <div className='bar' data-percentage='4'></div>
            </div>
            <div className='holder bar-four'>
              <span className='year'>Java</span>
              <div className='bar' data-percentage='5'></div>
            </div>

            <div className='holder bar-four'>
              <span className='year'>Excel</span>
              <div className='bar' data-percentage='5'></div>
            </div>
            <div className='holder bar-two'>
              <span className='year'>MongoDB</span>
              <div className='bar' data-percentage='3'></div>
            </div>
            <div className='holder bar-three'>
              <span className='year'>Node.js</span>
              <div className='bar' data-percentage='4'></div>
            </div>
            <div className='holder bar-three'>
              <span className='year'>PHP</span>
              <div className='bar' data-percentage='4'></div>
            </div>

            <div className='holder bar-four'>
              <span className='year'>Python</span>
              <div className='bar' data-percentage='5'></div>
            </div>
            <div className='holder bar-two'>
              <span className='year'>React.js</span>
              <div className='bar' data-percentage='3'></div>
            </div>
            <div className='holder bar-zero'>
              <span className='year'>React Native</span>
              <div className='bar' data-percentage='1'></div>
            </div>
            <div className='holder bar-one'>
              <span className='year'>SQL</span>
              <div className='bar' data-percentage='2'></div>
            </div>
          </div>

          <ScrollAnimation animateIn='fadeIn'>
            <div id='courses'>
              <p id='coursesHeader2'>Courses Taken</p>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <ul id='courseList'>
                  <li>Agile Software Development</li>
                  <li>Applied Internet Technology</li>
                  <li>Basic Algorithms</li>
                  <li>Computer Systems Organization</li>
                  <li>Data Management and Analysis</li>
                  <li>Data Structures</li>
                  <li>Inroduction to Computer Science</li>
                  <li>Web Development</li>
                </ul>
              </ScrollAnimation>
              {/* </ScrollAnimation> */}
            </div>
          </ScrollAnimation>
        </div>
      </Tab>
      {/* <Tab title='Extracurriculars'>Extracurriculars</Tab> */}
    </Tabs>
  );
}

export default Resume;
