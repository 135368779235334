import './App.css';
import Sidebar from './components/sidebar.js';
import NavBar from './components/navbar.js';
import Footer from './components/footer.js';
import Home from './components/home.js';
import Resume from './components/resume.js';
import About from './components/about.js';
import ContactMobile from './components/contactMobile.js';
import Portfolio from './components/portfolio.js';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from 'react-router-dom';
import Main from './components/tic-tac-toe/Main';

function App() {
  return (
    <Router>
      <div id='page-container'>
        <NavBar />

        <div className='main'>
          <Switch>
            <Route path='/resume'>
              <Resume />
            </Route>

            <Route path='/about'>
              <About />
            </Route>

            <Route path='/contact'>
              <ContactMobile />
            </Route>

            <Route path='/portfolio'>
              <Portfolio />
            </Route>

            <Route path='/tic-tac-toe'>
              <Main />
            </Route>

            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </div>
        <div id='footerTest'>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
