import ScrollAnimation from 'react-animate-on-scroll';
import { data } from './experience-data.js';
function ExperienceMap() {
  return (
    <ScrollAnimation animateIn='fadeIn'>
      {data.map((item, num) => {
        return (
          <ScrollAnimation key={num} className='resumeCard' initiallyVisible={true}>
            <div className='experience-flex'>
              <div className='experience-left'>
                <b>
                  <p className='highlight'>{item.company}</p>
                  <p>{item.position}</p>
                </b>
              </div>
              <div className='experience-right'>
                <b>
                  <p style={{textAlign: 'right', marginBottom: '0'}}>{item.location}</p>
                  <p style={{textAlign: 'right'}}>{item.date}</p>
                </b>
              </div>
            </div>

            <p>{item.description}</p>
          </ScrollAnimation>
        );
      })}
    </ScrollAnimation>
  );
}

export default ExperienceMap;
