function PortfolioGrid() {
  const data = [
    {
      href: 'https://www.dangle.games',
      src: 'dangle-grid.png',
      caption: 'Dangle (Hockey Wordle)',
    },
    {
      href: 'https://www.myflooringguynow.com',
      src: 'flooring.png',
      caption: 'Client Flooring Site',
    },
    {
      href: '/tic-tac-toe',
      src: 'tictactoe.png',
      caption: 'Tic Tac Toe',
    },
    {
      href: 'https://www.bakedbymelissa.com/menu.html',
      src: 'flavormenu.png',
      caption: 'Baked By Melissa Flavor Menu',
    },
    {
      href: 'https://www.bakedbymelissa.com/menu/tie-dye.html',
      src: 'bbmflavor.png',
      caption: 'Baked By Melissa Cupcake Info Page',
    },
    {
      href: 'https://www.bakedbymelissa.com/gifting-and-events',
      src: 'bbmgifts.png',
      caption: 'Baked By Melissa Corporate Gifts Page',
    },
    {
      href: 'https://www.bakedbymelissa.com/jobs',
      src: 'bbmjobs.png',
      caption: 'Baked By Melissa Jobs Page',
    }
  ];

  return (
    <ul className='grid-container2'>
      {data.map((item, num) => {
        return (
          <li key={num} className='grid-item2'>
            <a href={item.href} target='_blank'>
              <img src={item.src}></img>
            </a>
            <a href={item.href} target='_blank'>
              {item.caption}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default PortfolioGrid;
