import React from 'react';
import '../styles/about.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faSignature, faMapPin, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div id='about'>
      <Helmet>
        <title>About - Ryan Schwarz</title>
        <meta name='About' content='About' />
      </Helmet>
      <div className='aboutFlex'>
        <div style={{ textAlign: 'center' }} className='aboutPicture animate__animated animate__fadeInTopLeft animate__slow'>
          <img src='ryanHeadshot.png' alt='My photo' />
          <div style={{ textAlign: 'left', display: 'inline-block' }}>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faSignature} />
              </span>
              Ryan Joshua Schwarz
            </p>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faBirthdayCake} />
              </span>
              June 15th, 1999
            </p>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faMapPin} />
              </span>
              New York, NY
            </p>
            <a href='mailto: ryanjschwarz99@gmail.com'>
              <p>
                <span style={{ marginRight: '10px' }}>
                  <FontAwesomeIcon style={{ width: '4em' }} icon={faEnvelope} />
                </span>
                ryanjschwarz99@gmail.com
              </p>
            </a>
          </div>
        </div>
        <div style={{ textAlign: 'center' }} className='aboutContent animate__animated animate__fadeInBottomRight animate__slow'>
          <h2>ABOUT RYAN</h2>
          <div style={{ textAlign: 'left', display: 'inline-block' }}>
          <p>
            I'm Ryan! I just graduated from NYU with my Bachelor's Degree in Computer Science. 
            During my college career I had the opportunity to explore multiple fields and studies of interest,
             and found that computer science resonated with me most. I am fascinated with the intricacies of 
             software and software technologies. I’d like to be a part of technological evolution. 
             It strikes me as the most comprehensively fascinating thing happening in our world. 
             The appeal on the programming side of computer science is the psychological satisfaction of 
             seeing my mental product become a system in the real world. With a dash of intuition, a bit of theory, 
             and some judicious engineering calculations, combined with a creative spark, I can create systems that would 
             appear to be science fiction to someone living 100 years ago. And it is all the better when those systems make people's 
             lives easier. Please feel free to check out my  <span>
                <a href='/portfolio'>past work</a>{' '}
              </span>
              and my{' '}
              <span>
                <a href='/resume'>skills</a>
              </span>
              . Whether you are interested in my services or just want to chat, shoot me a message!
            </p>
          </div>
        </div>
      </div>

      <div className='mobileAbout'>
        <div style={{ textAlign: 'center' }} className='aboutPicture2'>
          <h2>ABOUT RYAN</h2>
          <img src='ryanHeadshot.png' className='img-fluid' alt='My photo' />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'left', display: 'inline-block' }}>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faSignature} />
              </span>
              Ryan Joshua Schwarz
            </p>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faBirthdayCake} />
              </span>
              June 15th, 1999
            </p>
            <p>
              <span style={{ marginRight: '10px' }}>
                <FontAwesomeIcon style={{ width: '4em' }} icon={faMapPin} />
              </span>
              New York, NY
            </p>
            <a href='mailto: ryanjschwarz99@gmail.com'>
              <p>
                <span style={{ marginRight: '10px' }}>
                  <FontAwesomeIcon style={{ width: '4em' }} icon={faEnvelope} />
                </span>
                ryanjschwarz99@gmail.com
              </p>
            </a>
          </div>
        </div>
        <div style={{ textAlign: 'center' }} className='aboutContent2'>
          {/* <h2>ABOUT RYAN</h2> */}
          <div style={{ textAlign: 'left', display: 'inline-block' }}>
            <p>
            I'm Ryan! I just graduated from NYU with my Bachelor's Degree in Computer Science. 
            During my college career I had the opportunity to explore multiple fields and studies of interest,
             and found that computer science resonated with me most. I am fascinated with the intricacies of 
             software and software technologies. I’d like to be a part of technological evolution. 
             It strikes me as the most comprehensively fascinating thing happening in our world. 
             The appeal on the programming side of computer science is the psychological satisfaction of 
             seeing my mental product become a system in the real world. With a dash of intuition, a bit of theory, 
             and some judicious engineering calculations, combined with a creative spark, I can create systems that would 
             appear to be science fiction to someone living 100 years ago. And it is all the better when those systems make people's 
             lives easier.Please feel free to check out my  <span>
                <a href='/portfolio'>past work</a>{' '}
              </span>
              and my{' '}
              <span>
                <a href='/resume'>skills</a>
              </span>
              . Whether you are interested in my services or just want to chat, shoot me a message!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
